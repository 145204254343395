@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.rubik {
  font-family: "Rubik", sans-serif;
}
.inter {
  font-family: "Inter", sans-serif;
}
